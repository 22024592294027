import React from "react";
import { graphql, Link } from "gatsby";
import "../styles/styles.css"
// import { GatsbyImage } from 'gatsby-plugin-image';
import Image from "gatsby-plugin-sanity-image";
import { SEO } from "../components/seo";
import NavBarTwo from "../components/NavbarTwo";
import Footer from "../components/Footer";
import ContactDetails from "../components/ContactDetails";
import Topics from "../components/Topics";





const d2cPage = ({ data }) => {

  return (
    <>

      <NavBarTwo />


      <section className="bg-hero-d2c-lg xl:bg-hero-d2c-xl flex flex-col justify-end sm:block pb-16 text-gray-400 bg-gray-900 body-font bg-no-repeat  bg-center	 bg-cover	 h-screen">
        <div className="container mx-auto max-w-7xl flex  px-5 sm:py-64 sm:flex-row flex-col items-center">
          <div className="lg:flex-grow max-w-sm md:max-w-lg md:w-1/2 -lg:pr-24 -md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center  bg-dark/40 py-6 px-4 rounded">
            <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
            <h1 className="title-font sm:text-5xl text-3xl mb-4 font-medium text-white">D2C Lösungen<span> </span>
              <br /><span className="text-primary">
                für Hersteller und Großhandel</span>
            </h1>
            <br />
            <p className="text-sm md:text-base mb-8 leading-relaxed text-white max-w-sm">Umfassende Gesamtpakete für Ihr D2C Projekt</p>
            <div className="flex-col lg:flex-row justify-center">
              <a href="#main" className=" inline-flex text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-primary rounded-full text-lg mb-6">mehr erfahren
              </a>

              <a href="#contacts" className="sm:ml-0 lg:ml-4  inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded-full text-lg">Kontaktaufnahme</a>
            </div>
          </div>

        </div>
      </section>


<section className="text-gray-600 body-font" id="main">
  <div className="container mx-auto max-w-7xl flex px-5 py-48 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
     <div className="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
      <h1 className=" title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">D2C
        <br className="hidden lg:inline-block"/> Lösungen für Grosshändler, Hersteller
      </h1>
      <p className="sm:text-lg mb-8 leading-relaxed">Als Hersteller oder Großhändler ist heutzutage der Schritt zur Vorwärtsintegration in Richtung Direktvertrieb wesentlich einfacher und verbreiteter als noch vor wenigen Jahren.<br/><br/>
Zahlreiche Vorteile machen das D2C Geschäft zu einem attraktiven und lukrativen Absatzkanal. Durch Marktplatzplattformen und moderne E-Commerce Shopsysteme kann der Einstieg ohne ein eigenes Filialnetz an Ladengeschäften erfolgen.
Etablierte Unternehmen verfügen häufig bereits über eine leistungsfähige Infrastruktur und können so einfach Synergien nutzen.
Bei Neugründungen oder kleineren Unternehmen mit attraktiven Produkten ist trotz dieser vergleichsweise niedrigen Einstiegsbarrieren ein Vorhaben nicht immer einfach umzusetzen. Meist nehmen die bestehenden Vertriebskanäle und Kundenbeziehungen die meiste Aufmerksamkeit und Kapazitäten in Anspruch. Ein Aufbau der notwendigen Expertise durch die Rekrutierung geeigneten Personals und Aufbau neuer Abteilungen ist im gesetzten finanziellen und zeitlichen Rahmen häufig weder möglich noch sinnvoll.<br/><br/>

Als Partner für den Aufbau und Betrieb umfassender E-Commerce Lösungen für Großhändler, Importeure und Hersteller verhelfen wir Ihnen in kurzer Zeit zum erfolgreichen Direct-To-Consumer Geschäft über alle relevanten Kanäle. 
 <br/><br/>



</p>
     
    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      
    <Image className="rounded-sm" {...data.sanityLevelOne.image} width={450} style={{
              width: '100%',
              objectFit: 'cover',
              opacity: '1',
              transition: 'opacity 500ms ease 0s'
            }} alt="D2C Lösungen" />
    </div>
  </div>
</section>   









      <section className="d2c-case pb-48">
        <div className="container max-w-7xl mx-auto pb-24 px-5">
          <h2 className=" title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Fallbeispiel Kundenprojekt D2C
          </h2>
          <p className="sm:text-lg mb-8 leading-relaxed">Welche Anforderungen und Lösungsansätze Teil eines D2C Projektes sein können, erfahren Sie im folgenden Fallbeispiel:</p>
        </div>

        <div className="container  max-w-7xl mx-auto">
          <ol className="md:ml-5 relative box-content md:border-l-4 border-gray-300 px-5 md:pl-10 text-gray-600 body-font">

            <li className="max-w-screen-lg mb-10 md:mb-16 md:ml-6 bg-slate-200 p-4 md:p-0 rounded-md box-content md:p-12">
              <span className="flex absolute md:-left-5 justify-center items-center w-9 h-9 bg-primary rounded-full ring-8 ring-transparent md:ring-white ">


                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>


              </span>
              <h3 className="relative left-12 md:left-0 flex items-center mb-1 sm:text-2xl text-2xl text-gray-900 font-medium title-font ">Unser Kunde</h3>

              <p className="leading-relaxed text-base sm:text-lg mt-8 mb-16">Firma A. aus B. produziert Produkte für Sanitär und Bad. Bisherige Abnehmer sind der Großhandel, der stationäre Fachhandel sowie eine nationale Baumarktkette.
                Für das B2B Geschäft ist eine web-basierte Orderplattform vorhanden, ca. die Hälfte Umsatzes des Umsatzes wird über den Vertriebsinnendienst erzielt.

              </p>

            </li>
            <li className="max-w-screen-lg mb-10 md:mb-16 md:ml-6 bg-slate-200 p-4 md:p-0 rounded-md box-content md:p-12">
              <span className="flex absolute md:-left-5 justify-center items-center w-9 h-9 bg-primary rounded-full ring-8 ring-transparent md:ring-white ">


                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>


              </span>
              <h3 className="relative left-12 md:left-0 flex items-center mb-1 sm:text-2xl text-2xl text-gray-900 font-medium title-font ">Das D2C Ziel</h3>

              <p className="leading-relaxed text-base sm:text-lg mt-8 mb-16">Aufgrund stagnierender Umsätze im stationären Einzelhandel sucht Firma A. nach neuen Möglichkeiten Umsätze zu generieren, bevorzugt zu besseren Margen als gegenwärtig im B2B Geschäft.<br />

                Firma A. kontaktiert uns mit der Idee direkt an Endverbraucher zu verkaufen und dem Auftrag hierfür die Konzeption und Umsetzung zu übernehmen. Mit Rücksicht auf bestehende B2B Geschäftsbeziehungen soll die Lösung jedoch so gestaltet werden, dass A. nicht nach außen als Direktanbieter erkennbar ist.
                <br />
                Die Umsetzung sollte so schnell und kostengünstig wie möglich stattfinden, idealerweise innerhalb von zwei bis drei Monaten.
              </p>

            </li>
            <li className="max-w-screen-lg mb-10 md:mb-16 md:ml-6 bg-slate-200 p-4 md:p-0 rounded-md box-content md:p-12">
              <span className="flex absolute md:-left-5 justify-center items-center w-9 h-9 bg-primary rounded-full ring-8 ring-transparent md:ring-white ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
                </svg>



              </span>
              <h3 className="relative left-12 md:left-0 flex items-center mb-1 sm:text-2xl text-2xl text-gray-900 font-medium title-font ">Das Konzept</h3>

              <p className="leading-relaxed text-base sm:text-lg mt-8 mb-16">Aufgrund der Vorgabe nicht als Händler in Erscheinung treten zu wollen und unter Berücksichtigung des gesteckten Zeitrahmens, stehen im konkreten Fall wenige Ansätze zur Auswahl:<br />

                Lösungen mit Eigenversand sind nicht nur aus den bereits genannten Gründen nicht möglich, sondern auch weil Bestellabwicklung und Logistik nicht für die Bearbeitung von Verkäufen an Endkunden ausgerichtet sind. <br /><br />
                Die naheliegende Lösung ist unter diesen Gegebenheiten der Verkauf über das Amazon FBA Netzwerk (Fulfilment by Amazon). Hierbei wird die angebotene Ware in der gewünschten Stückzahl zuerst an ein Amazon Logistikzentrum geschickt. Angeboten werden die Produkte über ein eigens eingerichtetes Amazon Konto mit einem Inhaber ohne Bezug zu Firma A.
                Bei dieser Art zu verkaufen wird nicht nur die Bearbeitung von Bestellungen bis hin zum Versand vollständig von Amazon abgewickelt, sondern auch Retouren sowie gewisse Aspekte des Kundensupports. Dies ermöglicht Firma A. über alle europäischen Amazon Plattformen und eine gigantische Anzahl potenzieller Käufer anzusprechen- und das alles ohne aufwendige Anpassungen an der eigenen Logistik vorzunehmen oder eine Infrastruktur für das Ordermanagement aufbauen zu müssen.
                <br />
                <br />

                Bei Bedarf können weitere Marktplätze wie z.B. Kaufland oder Ebay integriert werden. Auch hier kann Amazon als Fulfilment Dienstleister fungieren: mit dem MCF (Multi-Channel-Fulfilment) liefert Amazon die bestellten Waren auch an Kunden von Drittplattformen. Alternativ bieten weitere Dienstleister der Logistikbranche teilweise vergleichbare Lösungen.

              </p>

            </li>
            <li className="max-w-screen-lg mb-10 md:mb-16 md:ml-6 bg-slate-200 p-4 md:p-0 rounded-md box-content md:p-12">
              <span className="flex absolute md:-left-5 justify-center items-center w-9 h-9 bg-primary rounded-full ring-8 ring-transparent md:ring-white ">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                </svg>



              </span>
              <h3 className="relative left-12 md:left-0 flex items-center mb-1 sm:text-2xl text-2xl text-gray-900 font-medium title-font ">Die Umsetzung</h3>

              <p className="leading-relaxed text-base sm:text-lg mt-8 mb-16">
                Die ersten Schritte für die Implementierung des D2C Konzepts beinhalten die Aufbereitung der bereits vorhandenen Produktdaten für eine optimale Produktpräsentation auf Amazon.
                Hierzu gehören die Amazon Markenanmeldung und die Neuanlage von Produktlistings beziehungsweise die Überarbeitung und Optimierung bestehender Listings. Darüber hinaus erstellen wir einen Markenshop und A+ Content für eine ansprechende optische Darstellung der Produktpalette.
                Eine Anbindung der Warenwirtschaft oder eines Order Management Systems ist bei dieser reinen FBA Lösung nicht zwingend erforderlich, wodurch sowohl Zeitaufwand als auch Kosten gering gehalten werden können.
                <br /><br />
                Für einen bestmöglichen Start aber auch als zentrales Element im weiteren Verlauf verhelfen Amazon PPC (Pay Per Click) Kampagnen zu höherer Sichtbarkeit und gleichzeitig mehr Umsatz. Bei diesem Kunden übernehmen wir Konzeption, Einrichtung und Pflege aller PPC Kampagnen. <br />Über wöchentliche Berichte erhält unser Kunde wichtige Einblicke in alle relevanten Aspekte der Werbeaktivitäten:     </p> <br />
              <ul className="leading-relaxed text-base sm:text-lg mb-16 list-disc pl-6">
                <li className="leading-relaxed text-base sm:text-lg">welche Produkte verkaufen sich besonders gut bzw. welche könnten besser laufen?</li>
                <li className="leading-relaxed text-base sm:text-lg">welchen Return liefern die unterschiedlichen Kampagnenbestandteile?</li>
                <li className="leading-relaxed text-base sm:text-lg">bewegen sich CPA (Kosten pro Verkauf/Conversion) im gesteckten Rahmen?</li>
              </ul>



            </li>
            <li className="max-w-screen-lg mb-10 md:mb-16 md:ml-6 bg-slate-200 p-4 md:p-0 rounded-md box-content md:p-12">
              <span className="flex absolute md:-left-5 justify-center items-center w-9 h-9 bg-primary rounded-full ring-8 ring-transparent md:ring-white ">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                </svg>


              </span>
              <h3 className="relative left-12 md:left-0 flex items-center mb-1 sm:text-2xl text-2xl text-gray-900 font-medium title-font ">Betrieb und Wachstum</h3>

              <p className="leading-relaxed text-base sm:text-lg mt-8 mb-16">
                Auch nach Start- und Anlaufphase gibt es zahlreiche xx, bei denen wir unserem Kunden im D2C Geschäft per Amazon zur Seite stehen:
                - Kampagnenpflege und Optimierung der PPC Werbeaktivitäten
                (- Content Management: )
                - Listung neuer Produkte
                - Optimierung bestehender Listings
                - Saisonale Anpassung des Markenshops
              </p>

            </li>


          </ol>

        </div>
      </section>






      <section className="text-gray-50 body-font bg-dark bg-center bg-wavy bg-no-repeat bg-cover">
        <div className="container max-w-7xl px-5 py-24 mx-auto flex flex-col mb-24 md:mb-64">
          <div id="contacts" className="lg:w-4/6 mx-auto">
            <div className="flex flex-col sm:flex-row mt-10">
              <ContactDetails />
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-primary sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left ">
                <p className="leading-relaxed text-lg mb-4 text-white">Sind Sie bereit für Ihr D2C E-Commerce Projekt? Wir beraten plattformunabhängig mit Ihren individuellen Ziele und Anforderungen im Mittelpunkt. Sichern Sie sich noch heute Ihr unverbindliche Erstgespräch. Mein Team und ich freuen uns auf Sie!  </p>
                <a className="cursor-pointer text-primary inline-flex items-center" href="mailto:hallo@digitscom.de?subject=Unverbindliches Erstgespräch: D2C Lösungen für Großhandel und Hersteller&body=Guten Tag Herr Kurz,%0D%0A%0D%0A
            ich interessiere mich für eine kostenlose Erstberatung zum Thema D2C Lösungen.%0D%0A%0D%0A%0D%0ABitte nehmen Sie mit mir für eine kurzfristige Terminvereinbarung Kontakt auf.%0D%0A(Untenstehende Angaben bitte vervollständigen.)%0D%0A%0D%0A%0D%0AName:%0D%0AFirma:%0D%0ATel.:%0D%0AE-Mail:%0D%0A">Jetzt Erstgespräch sichern
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <div className="mark">


    </div>
          </div>
        </div>
      </div>
    </div>
</section>

<Topics/>


{/* <section className="text-gray-600 body-font relative">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-12">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Contact Us</h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify.</p>
    </div>
    <div className="lg:w-1/2 md:w-2/3 mx-auto">
      <div className="flex flex-wrap -m-2">
        <div className="p-2 w-1/2">
          <div className="relative">
            <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
            <input type="text" id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-primary focus:bg-white focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
          </div>
        </div>
        <div className="p-2 w-1/2">
          <div className="relative">
            <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-primary focus:bg-white focus:ring-2 focus:ring-yellow-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
          </div>
        </div>
        <div className="p-2 w-full">
          <div className="relative">
            <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-primary focus:bg-white focus:ring-2 focus:ring-yellow-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
        </div>
        <div className="p-2 w-full">
          <button className="flex mx-auto text-white bg-primary border-0 py-2 px-8 focus:outline-none hover:bg-primary-light rounded text-lg">Button</button>
        </div>
        <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
          <a className="cursor-pointer text-primary">example@email.com</a>
          <p className="leading-normal my-5">49 Smith St.
            <br/>Saint Cloud, MN 56301
          </p>
          <span className="inline-flex">
            <a className="cursor-pointer text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a className=" cursor-pointerml-4 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a className=" cursor-pointerml-4 text-gray-500">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
            <a className=" cursor-pointerml-4 text-gray-500">
              <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</section> */}

<Footer/>
</>
    )
}

export default d2cPage;

export const Head = ({ data }) => {
  return (
    <>
      <SEO />
    </>
  )
}

export const dataWebDev = graphql`
query MyD2cQuery {
  sanityLevelOne(id: {eq: "-9ca05c71-d070-5e71-a2b2-30d80ffa7699"}) {
    id
    image {
        ...ImageWithPreview
    }
    intro_content
    intro_title
    meta_description
    title
  }
}
`